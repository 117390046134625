import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import addToMailchimp from "gatsby-plugin-mailchimp";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import {
  colors,
  hexRgba,
  mediaquery,
  rem,
  scaleSpacing,
  getMailChimpEndpoint,
} from "../../../../styles/theme";
import Link from "../../Atoms/Link";
import { NewsletterModalSection } from "../Newsletter";
import Svg from "../../Atoms/Svg";
import { Noto, NotoBody, OswaldH3, OswaldH4 } from "../../Atoms/Typography";
import { UIContext } from "../../../contexts/ui";

const Container = styled.div`
  background: ${colors.white};
  position: relative;
  display: flex;
  padding: 0;

  flex-direction: column;
  justify-content: center;
  overflow-y: auto;

  ${mediaquery.md(css`
    height: 100%;
  `)};
`;
const Panels = styled.div`
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: center;

  ${mediaquery.md(css`
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
  `)};
`;

const FirstPanel = styled.div`
  position: relative;
  margin-top: ${scaleSpacing(7)};
  margin-bottom: ${scaleSpacing(10)};

  ${mediaquery.md(css`
    margin: 0;
    width: calc(var(--col) * 6 - var(--gutter-size));
  `)};
`;

const Title = styled.h2`
  ${OswaldH4};
  color: ${colors.black};
  margin-right: ${scaleSpacing(10)};
  margin-bottom: ${scaleSpacing(4)};

  ${mediaquery.sm(css`
    ${OswaldH3};
    margin-right: 0;
  `)};

  ${({ inPopin }) => inPopin && css``};
`;

const Subtitle = styled.p`
  ${NotoBody};
  color: ${colors.black};
  margin-right: ${scaleSpacing(10)};

  ${mediaquery.sm(css`
    margin-right: 0;
  `)}
`;

const StyledLink = styled(Link)`
  color: ${colors.white};
`;

const LegalsMention = styled.div`
  ${Noto};
  font-size: ${rem(11)};
  line-height: ${rem(14)};

  letter-spacing: 0.2px;
  margin-top: ${scaleSpacing(4)};
  align-self: flex-end;
  color: ${colors.black};
  a {
    display: inline;
    color: ${colors.black};
    font-size: ${rem(11)};
    line-height: ${rem(14)};
  }
`;

const SecondPanel = styled.div`
  grid-column: span 4;
  margin-top: ${scaleSpacing(3)};
  margin-bottom: ${scaleSpacing(7)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mediaquery.sm(css`
    margin-top: 0;
    grid-column: span 14;
  `)};

  ${mediaquery.md(css`
    margin-bottom: 0;
    grid-column: "none";
    width: calc(var(--col) * 8 - var(--gutter-size));
  `)};
`;

const Form = styled.form`
  width: 100%;
  margin: 0 auto;
  margin-top: ${scaleSpacing(5)};
  margin-bottom: ${scaleSpacing(6)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mediaquery.md(css`
    flex-direction: row;
    align-items: center;
    max-width: none;
    margin-top: ${scaleSpacing(5)};
    margin-bottom: 0;
  `)}
`;

const Input = styled.input`
  width: 100%;
  height: ${scaleSpacing(14)};
  padding: 0 ${scaleSpacing(6)};
  border-radius: ${scaleSpacing(7)};
  border: none;
  outline: none;
  ${NotoBody};
  color: ${colors.black};
  background: ${colors.white};
  border: 1px solid ${hexRgba(colors.black, 0.8)};
  margin-top: ${scaleSpacing(4)};

  ${mediaquery.md(css`
    margin-left: ${scaleSpacing(4)};
    margin-top: 0;
  `)}

  &::placeholder {
    color: ${colors.black};
  }
  &:disabled {
    color: ${colors.mediumgrey3};
  }

  &:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${colors.black} !important;
  }
`;

const InputWithIcon = styled.div`
  width: 100%;
  position: relative;
  input {
    margin-top: 0;
  }
  ${mediaquery.sm(css`
    input {
      padding-right: ${scaleSpacing(12)};
      margin-right: ${scaleSpacing(4)};
      margin-left: 0;
    }
  `)};
`;

const IconContainer = styled.div`
  position: absolute;
  width: ${rem(24)};
  height: ${rem(24)};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: ${scaleSpacing(6)};
  transform: translateY(-50%);
`;

const MessageWrapper = styled(animated.div)`
  position: absolute;
  width: calc(100% - ${scaleSpacing(2)});
  background: ${colors.white};
  padding: ${scaleSpacing(4)} ${scaleSpacing(6)};
  border-radius: ${rem(5)};
  bottom: ${rem(90)};
  left: ${scaleSpacing(1)};
  box-shadow: 0px 3px 20px 0px ${hexRgba(colors.black, 0.3)};
  display: flex;
  align-items: center;
  flex-direction: column;

  &:before {
    content: "";
    position: absolute;
    width: ${scaleSpacing(2)};
    height: ${scaleSpacing(2)};
    border-radius: ${rem(2)};
    background: ${colors.white};
    transform: translate3d(-50%, 0, 0) rotate(45deg);
    bottom: -${rem(4)};
    left: 50%;
  }
  ${mediaquery.md(css`
    left: ${rem(10)};
    width: 44%;
    bottom: ${scaleSpacing(20)};
  `)};
`;

const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const StatusContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${scaleSpacing(8)};
  height: ${scaleSpacing(10)};
  background: ${colors.lightgrey};
  border-radius: ${scaleSpacing(4)};
`;

const Message = styled.div`
  ${Noto};
  font-size: ${rem(10)};
  line-height: ${rem(18)};

  color: ${colors.darkgrey1};
  margin-left: ${scaleSpacing(2)};

  a {
    color: ${colors.black};
    font: inherit;
  }
`;
const Sections = styled.div`
  display: flex;
  flex-direction: column;
`;

const NewsletterModal = ({
  title,
  subtitle,
  legalsMention,
  legalsMentionUrl,
  inPopin,
  mini,
  ctaLabel,
  ctaLink,
  sections,
}) => {
  const inputRef = useRef(null);
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  const [hasReset, setHasReset] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [newsletterIds, setNewsletterIds] = useState([
    "curiosity",
    "institut",
    "trois_couleurs",
    "paradiso",
  ]);

  const { openModal } = useContext(UIContext);

  let timeout;
  const { t } = useTranslation();
  const transition = useTransition(showMessages, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 0 },
  });

  const handleOnSelect = useCallback(
    newsletterId => {
      setNewsletterIds(
        () =>
          newsletterIds.includes(newsletterId)
            ? newsletterIds.filter(i => i !== newsletterId) // remove item
            : [...newsletterIds, newsletterId] // add item
      );
    },
    [newsletterIds]
  );

  const handleAddToMailchimp = async newsletterId => {
    const response = await addToMailchimp(
      inputRef.current.value,
      {},
      getMailChimpEndpoint(newsletterId)
    );
    return response;
  };

  const handleSubmit = async e => {
    inputRef.current.blur();
    setMessages(() => []);
    e.preventDefault();

    await handleSubmitNewsletter();
    timeout = setTimeout(() => {
      handleReset();
    }, 5000);
  };

  const handleSubmitNewsletter = async () => {
    Promise.all(
      newsletterIds.map(async newsletterId => {
        try {
          const response = await handleAddToMailchimp(newsletterId);
          return response;
        } catch (error) {
          return error;
        }
      })
    )
      .then(messages => {
        setMessages(messages);
      })
      .catch(error => {
        setMessages([error]);
      });
  };

  const handleReset = useCallback(() => {
    setShowMessages(false);
    setHasReset(true);
    setMessages(() => []);
    setText("");
  }, []);

  const handleFocus = () => {
    if (messages) {
      clearTimeout(timeout);
      setShowMessages(false);
    }
  };

  const openSuccessModal = useCallback(() => {
    openModal({
      modalId: "newsletterSuccess",
    });
  }, [openModal]);

  useEffect(() => {
    if (messages?.find(({ result }) => result === "success")) {
      openSuccessModal();
    } else if (messages.length) {
      setShowMessages(true);
    }
  }, [openSuccessModal, messages]);

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
  }, [timeout]);

  const currentWebsitesNewsletter = sections.find(nw => {
    if (nw.id === process.env.GATSBY_WEBSITE) {
      return nw;
    }
  });

  const orderedNewsletters = sections.filter(
    nw => nw.id !== process.env.GATSBY_WEBSITE
  );

  if (currentWebsitesNewsletter) {
    orderedNewsletters.splice(0, 0, currentWebsitesNewsletter);
  }

  const sectionsToShow = orderedNewsletters || sections;

  return (
    <Container inPopin={inPopin}>
      <Panels>
        <FirstPanel>
          {title && <Title>{title}</Title>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}

          {ctaLabel && (
            <StyledLink link={{ url: ctaLink }}>{ctaLabel}</StyledLink>
          )}

          {legalsMention && (
            <LegalsMention>
              {legalsMention}{" "}
              <Link link={{ url: legalsMentionUrl }}>
                {t("common_newsletter_legalsmentionlinklabel")}
              </Link>
            </LegalsMention>
          )}
        </FirstPanel>

        <SecondPanel mini={mini} inPopin={inPopin}>
          <Sections>
            {sectionsToShow.map((section, index) => {
              if (section) {
                return (
                  <NewsletterModalSection
                    key={index}
                    {...section}
                    onSelect={handleOnSelect}
                    hasReset={hasReset}
                  />
                );
              }
            })}
          </Sections>
        </SecondPanel>
      </Panels>
      <Form onSubmit={handleSubmit} mini={mini}>
        <InputWithIcon
          css={
            mini &&
            css`
              ${mediaquery.md(css`
                width: auto;
                margin-right: ${scaleSpacing(2)};
              `)};
            `
          }
        >
          <Input
            onFocus={handleFocus}
            ref={inputRef}
            placeholder={t("common_newsletter_email_placeholder")}
            value={text}
            onChange={e => setText(e.target.value)}
            type="email"
            name="EMAIL"
            required
            mini={mini}
          />
          <IconContainer>
            <Svg name="arrowlink" size={10} height={14} fill={colors.black} />
          </IconContainer>
        </InputWithIcon>
        {transition(
          (style, item) =>
            item && (
              <MessageWrapper style={style}>
                {messages?.map((message, i) => (
                  <MessageContainer key={i}>
                    <StatusContainer>
                      {message.result === "success" ? (
                        <Svg
                          name="tick"
                          size={15}
                          height={15}
                          fill={colors.cidarkgrey2nema}
                        />
                      ) : (
                        <Svg
                          name="warning"
                          size={4}
                          height={15}
                          fill={colors.cinema}
                        />
                      )}
                    </StatusContainer>
                    <Message
                      key={i}
                      dangerouslySetInnerHTML={{ __html: message.msg }}
                    />
                  </MessageContainer>
                ))}
              </MessageWrapper>
            )
        )}
        <Input
          css={css`
            color: ${colors.white};
            background: ${colors.black};
            &:disabled {
              background: ${colors.darkgrey3};
            }
          `}
          type="submit"
          value="Valider"
          name="subscribe"
          id="mc-embedded-subscribe"
          className="button"
          disabled={!newsletterIds.length || text === ""}
        />
      </Form>
    </Container>
  );
};

NewsletterModal.propTypes = {
  theme: PropTypes.string.isRequired,
  emojis: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  legalsMention: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  legalsMentionUrl: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  inPopin: PropTypes.bool.isRequired,
  mini: PropTypes.bool,
  sections: PropTypes.array,
};

NewsletterModal.defaultProps = {
  theme: "institut",
  inPopin: false,
  mini: false,
  sections: [],
};

export default React.memo(NewsletterModal);
